/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

/*
 * Footer used across all the pages
 */

import React, { Suspense, useEffect, useState, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { lazy } from '@loadable/component';
import styles from './footerNew.module.css';
import axios from 'axios';
import parser from 'html-react-parser';

const Img = lazy(() => import('../Img'));

const FooterWrapper = styled.div`
@media (min-width:992px){
  .container{
  max-width: 100%;
padding: 0 50px;
  }
  .awardsSecondCol.col-md-5.col-sm-12.col-12 {
    flex: 28%;
    max-width: 28%;
}
`;

const CustomFooter = props => {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);
  const [footerInfoData, setFooterInfoData] = useState();

  let location = useLocation();

  
  useEffect(() => {
    /**
     * @author Prerna Dubey
     * @date 07/23/2023
     * The below function is used to fetch the swmc footer data which
     * is stored in master JSON in S3 bucket.
     * @returns JSON Object of the requested file
     */
    const fetchSWMCFooterInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_SWMC_FOOTET_INFO)
          .then((response) => {
            //console.log(response.data);
            setFooterInfoData(response.data);
            const observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting);
                observer.disconnect();
              }
            });
            observer.observe(ref.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
              observer.disconnect();
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchSWMCFooterInfo();
  }, []);

  const isUrlPavan = location.pathname.toLowerCase() === '/pavan';

  const imagesPath = process.env.REACT_APP_CDN_LINK + '/swmc-images/';

  let date = new Date();
  let currentYear = date.getFullYear();
  return (
    <FooterWrapper>
      <div>
        <footer>
          {footerInfoData ?
            <>
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12} className={styles.desctypo}>
                    <div className={styles.logosize}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <a href={footerInfoData.sunWestLogoInfo.url} target="_blank" 
                            rel="noopener" title={footerInfoData.sunWestLogoInfo.title}>
                            <img
                              src={footerInfoData.sunWestLogoInfo.imgPath}
                              alt={footerInfoData.sunWestLogoInfo.alt}
                              className={styles.footer_logo}
                              loading="lazy"
                            />
                          </a>
                        </Img>
                      </Suspense>
                    </div>
                    <br />
                    <div className={styles.footer_desc_text}>
                      {parser(footerInfoData.disclaimer)}
                    </div>
                    <div className={styles.footer_desc_text}>
                    {process.env.REACT_APP_STATE_DOMAIN !==
                        "ny.swmc.com" ? (
                          <>
                           {parser(footerInfoData.nyStateWithSunWestMortgageDisclaimer)}
                          </>
                        ) : (
                          ""
                        )}
                    </div>
                  </Col>
                  <Col
                    md={{ span: 6, offset: 0 }}
                    lg={6}
                    sm={12}
                    xs={12}
                    className={styles.contactus}
                  >
                    <div className={styles.contact_block}>
                      <h4>{parser(footerInfoData.contactUs.heading)}</h4>

                      <div className={styles.ul_footer + ' ' + styles.address}>
                        <div
                          className={styles.contactflex}
                          style={{ display: 'flex' }}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <Img>
                              <img
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginTop: '4px',
                                }}
                                src={footerInfoData.contactUs.address.imgPath}
                                alt={footerInfoData.contactUs.address.alt}
                              />
                            </Img>
                          </Suspense>
                          <p>
                            <a
                              style={{ color: '#fff' }}
                              target="_blank"
                              href={footerInfoData.contactUs.address.googleMapsUrl}
                              rel="noopener"
                            >
                              {footerInfoData.contactUs.address.fullAddress}
                            </a>
                          </p>
                        </div>
                        <div className={styles.contactOptions}>
                          <div
                            className={styles.contactflex}
                            style={{ display: 'flex' }}
                          >
                            <Suspense fallback={<div>Loading...</div>}>
                              <Img>
                                <img
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginTop: '4px',
                                  }}
                                  src={footerInfoData.contactUs.phone.imgPath}
                                  alt={footerInfoData.contactUs.phone.alt}
                                />
                              </Img>
                            </Suspense>
                            <p>
                              <a href={"tel:" + footerInfoData.contactUs.phone.phoneNumberWithIntlCode} style={{ color: '#fff' }}>
                                {footerInfoData.contactUs.phone.phoneNumber}
                              </a>
                            </p>
                          </div>
                          <div className={styles.email}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Img>
                                <img
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginTop: '4px',
                                  }}
                                  src={footerInfoData.contactUs.email.imgPath}
                                  alt={footerInfoData.contactUs.email.alt}
                                />
                              </Img>
                            </Suspense>
                            <p>
                              <a
                                href={"mailto:" + footerInfoData.contactUs.email.email}
                                style={{ color: '#fff' }}
                              >
                                {footerInfoData.contactUs.email.email}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row />
              </Container>
              <hr style={{ borderTop: '1px solid #fff', opacity: 'unset' }} />
              <Container>
                <Row className="text-center">
                  <div className={styles.connectwithUs_block}>
                    <h3>{footerInfoData.connectWithUs.heading}</h3>
                  </div>
                </Row>
                <Row className="text-center">
                  <Col>
                    <div className={styles.social_footer}>
                      {footerInfoData.connectWithUs.socialMediaLinks.map((socialMediaLinksData, i) => (
                        <a
                          key={i}
                          href={socialMediaLinksData.url}
                          target="_blank"
                          rel="noopener"
                          title={socialMediaLinksData.title}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <Img>
                              <img
                                className={styles.marginImg}
                                style={{
                                  margin: '15px 30px 10px 30px',
                                }}
                                src={socialMediaLinksData.imgPath}
                                alt={socialMediaLinksData.alt}
                              />
                            </Img>
                          </Suspense>
                        </a>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr style={{ borderTop: '1px solid #fff', opacity: 'unset' }} />
              <Container>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Col xl={1} lg={1} md={3} sm={4} xs={3}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Img>
                        <a>
                          <img
                            src={footerInfoData.companyAwards.mortgageExecutiveTop50.imgPath}
                            className={styles.top50}
                            alt={footerInfoData.companyAwards.mortgageExecutiveTop50.alt}
                            title={footerInfoData.companyAwards.mortgageExecutiveTop50.title}
                            loading="lazy"
                          />
                        </a>
                      </Img>
                    </Suspense>
                  </Col>
                  <Col xl={2} lg={3} md={3} sm={4} xs={4}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Img>
                        <a
                          href={footerInfoData.companyAwards.bbbRating.url}
                          target="_blank"
                          rel="noopener"
                          title={footerInfoData.companyAwards.bbbRating.title}
                        >
                          <img
                            src={footerInfoData.companyAwards.bbbRating.imgPath}
                            className={styles.bbb}
                            alt={footerInfoData.companyAwards.bbbRating.alt}
                            loading="lazy"
                          />
                        </a>
                      </Img>
                    </Suspense>
                  </Col>
                  <Col xl={2} lg={2} md={3} sm={4} xs={4}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Img>
                        <a
                          href={footerInfoData.companyAwards.bcaRating.url}
                          target="_blank"
                          rel="noopener"
                          title={footerInfoData.companyAwards.bcaRating.title}
                        >
                          <img
                            src={footerInfoData.companyAwards.bcaRating.imgPath}
                            className={styles.alliance}
                            alt={footerInfoData.companyAwards.bcaRating.alt}
                            loading="lazy"
                          />
                        </a>
                      </Img>
                    </Suspense>
                  </Col>
                  <Col lg={1} md={4} sm={4} xs={4} className={styles.ehoBirdeye}>
                    <div ref={ref} className={styles.birdeyeContainer}>
                      {isIntersecting ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Img>
                            <a
                              href={footerInfoData.companyAwards.birdEyeReviews.url}
                              target="_blank"
                              rel="noopener"
                            >
                              <iframe
                                name="bfiframe"
                                title="birdeye"
                                className={styles.reviewIcon}
                                src={parser(footerInfoData.companyAwards.birdEyeReviews.iframeUrl)}
                                id="bfpublish"
                                scrolling="no"
                                style={{
                                  borderWidth: '0px',
                                  display: 'block',
                                  overflow: 'hidden',
                                  frameborder: '0',
                                  height: '75px',
                                }}
                              />
                        </a>
                        <p
                          style={{ textAlign: 'center' }}
                          className={styles.birdeye}
                        >
                          Powered by{' '}
                          <a
                                href={footerInfoData.companyAwards.birdEyeReviews.poweredByUrl}
                                target="_blank"
                                rel="noopener"
                              >
                                Birdeye
                              </a>
                            </p>
                          </Img>
                        </Suspense>
                      ) : (
                        <div
                          style={{
                            width: '165px',
                            height: '113px',
                            display: 'flex',
                            alignItems: 'ceter',
                            textAlign: 'center',
                          }}
                        >
                          Birdeye
                        </div>
                      )}
                    </div>
                  </Col>

                <Col lg={2} md={3} sm={4} xs={4}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Img>
                        <img
                          src={footerInfoData.companyAwards.equalHousingOpportunity.imgPath}
                          alt={footerInfoData.companyAwards.equalHousingOpportunity.alt}
                          title={footerInfoData.companyAwards.equalHousingOpportunity.title}
                          className={styles.homicon + ' ' + 'img-fluid'}
                          loading="lazy"
                          width={'100%'}
                          height={'100%'}
                        />
                      </Img>
                    </Suspense>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Col lg={5} md={5} sm={6} xs={12}>
                    <div
                      className={styles.cellLogoDiv}
                      style={{ display: 'contents' }}
                    >
                      <a href={footerInfoData.companyAwards.poweredByCelligence.url} target="_blank">
                        <img
                          className={styles.cellLogoImg}
                          src={footerInfoData.companyAwards.poweredByCelligence.imgPath}
                          style={{
                            maxWidth: '100%',
                            width: '323px',
                            marginTop: '30px',
                            height: '70px',
                          }}
                          title={footerInfoData.companyAwards.poweredByCelligence.title}
                          alt={footerInfoData.companyAwards.poweredByCelligence.alt}
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr style={{ borderTop: '1px solid #fff', opacity: 'unset', marginBottom: 'unset'}} />
              <Container className={styles.footer_link_banner}>
                <Row className={styles.footcont}>
                  <Col
                    xl={4}
                    sm={12}
                    className={styles.padleft0 + ' ' + styles.copyrighttxt }
                  >
                    <p className={styles.ul_footer + ' ' + styles.footer_list} style={{ textAlign: 'left' }}>
                      © {currentYear} Sun West Mortgage Company, Inc. All Rights
                      Reserved
                    </p>
                  </Col>
                  <Col
                    xl={8}
                    sm={12}
                    className={
                      styles.padleft0 +
                      ' ' +
                      styles.padright0 +
                      ' ' +
                      styles.dislinks
                    }
                  >
                    <ul
                      className={
                        styles.ul_footer +
                        ' ' +
                        styles.footer_list +
                        ' ' +
                        styles.linklist
                      }
                    >
                      {footerInfoData.footerLinks.map((footerLinksData, j) => (
                        <li key={j}>
                          <a href={footerLinksData.url}
                            style={{ color: '#fff' }}
                            title={footerLinksData.title}
                          >
                            {footerLinksData.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </>
          : ""}
        </footer>
      </div>
    </FooterWrapper>
  );
};

export default CustomFooter;